// Core variables and mixins
@import "variables";
@import "bootstrap";
@import "mixins";
@import "fonts";
@import "core";
@import "plugins";
@import "font-awesome/font-awesome";

body {
    background-color: #fff;
}


.wrapper-border {
    width: 100%;
    float: left;
}

.wrapper {
    width: 100%;
    float: left;
    min-height: 100vh;
    border: 5px solid $bg;
    padding: 0;
    @media(min-width: $screen-sm-min) {
        border: 20px solid $bg;
    }
    @media(min-width: $screen-lg-min) {
        border: 40px solid $bg;
    }
}

// Preloader
.preloader{
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: #fff;
    z-index: 99999;
    text-align: center;
    
    display: -webkit-box ;
    display: -webkit-flex ;
    display: -moz-flex ;
    display: -ms-flexbox ;
    display: flex ;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;

}
 

.preloader-content img {
    display: block;
    margin-bottom: 30px;

}

.preloader .icon-bcool{
    display: inline-block;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid $theme-primary; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    margin: 0 auto;

}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}





// full width
.full-width {
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
    @media(min-width: 2200px) {
        max-width: 1800px;
    }
 
}


// Nav
.header {
    //position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    padding-bottom: 5px;
    @include clearfix;


    .top-menu {
        width: 100%;
        float: left;
        height: auto;
        text-align: center;
        position: relative;
        @media(min-width: $screen-md-min) and (orientation: landscape){
            text-align: left;
        }

    }


    .container {
        position: relative;
    }

    .logo {
        
        display: inline-block;
        margin: 15px auto;
        
        @media(min-width: $screen-md-min) and (orientation: landscape){
            float: left;
            margin: 30px 0 0 0;
            padding-left: 30px;
        }
        @media(min-width: $screen-lg-min) {
           
            margin: 45px 0 0 0;
        }

        a {
            display: block;
            height: 70px;
            width: 70px;
            background-image: url('../img/logo.png');
            background-image: url('../img/logo.svg');
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center center;
            @media(min-width: $screen-md-min) and (orientation: landscape) {
                height: 80px;
                width: 80px;
            }
            @media(min-width: $screen-lg-min) {
                height: 120px;
                width: 120px;
            }
            img {
                height: 100%;
                width: auto;
            }
            span {
                display: none;
            }

        }


    }

    .social-m {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        float: left;
        position: absolute;
        top: 20px;
        left: 20px;
        font-size: 12px;
        li {
            float: left;
            display: inline-block;
            padding: 0 5px 0 0;
            margin: 0;
        }
    }

    .primary-nav {
        display: inline-block;
        float: right;
        height: 100%;
        padding-right: 0;
        width: 100%;

        @media(min-width: $screen-md-min) and (orientation: landscape){
            width: 75%;
        }

        .nav-trigger {
            position: absolute;
            top: 20px;
            display: table;
            height: auto;
            padding: 0 1em;
            font-size: 10px;
            text-transform: uppercase;
            color: $theme-primary;
            font-weight: bold;
            right: 20px;
            //border-left: 1px solid shade($color-4, 5%);

            &:hover, &:focus, &.active {
                text-decoration: none;
            }

            span {

                /* vertically align inside parent element */
                display: table-cell;
                vertical-align: middle;
            }

            em, em::after, em::before {

                /* this is the menu icon */
                display: block;
                position: relative;
                height: 2px;
                width: 22px;
                background-color: $theme-primary;
                backface-visibility: hidden;
            }

            em {

                /* this is the menu central line */
                margin: 10px auto;
                transition: background-color .2s;
                background-color: rgba($theme-primary, 0);
            }

            em::before, em::after {
                position: absolute;
                content: '';
                left: 0;
                transition: transform .2s;
            }

            em::before {

                /* this is the menu icon top line */

                transform: rotate(-45deg);
                @include rotate(-45deg);
            }

            em::after {

                /* this is the menu icon bottom line */

                transform: rotate(45deg);
                @include rotate(45deg);
            }

            @media(min-width: $screen-md-min) and (orientation: landscape){
                display: none;
            }

        }

        .nav-trigger.collapsed {
            em {

                /* transform menu icon into a 'X' icon */

                background-color: $theme-primary;
            }

            em::before {

                /* rotate top line */
                transform: translateY(-6px);
                @include translateY(-6px);
            }

            em::after {

                /* rotate bottom line */
                transform: translateY(6px);
                @include translateY(6px);
            }

        }
        #navigation {

        }
        ul {
            z-index: 2;
            padding: 0;
            margin: 0;
            font-family: $title-font;
            //display: none;
            list-style: none;

            @media(min-width: $screen-md-min) and (orientation: landscape){
                padding: 15px 0;
            }
            li {
                margin: 0;
                padding: 0 30px;
                &.active a {
                     &:after {
                        
                        width: 100%;
                        
                    }
                }
            }
            a {

                /* target primary-nav links */
                display: inline-block;
                color: $theme-primary;
                font-size: 14px;
                font-weight: normal;
                text-transform: uppercase;
                border-bottom: 1px solid transparent;
                position: relative;
                &:after {
                    content: '';
                    width: 0;
                    height: 1px;
                    background-color: $theme-primary;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include transition(all linear .2s);
                }
                &:hover, &:focus, &.active {
                    color: $theme-primary;
                    text-decoration: none;
                    &:after {
                        
                        width: 100%;
                        
                    }
                }

            }

        }
        .social {
            margin-top: 15px;
            li {
                float: left;
                display: inline-block;
                padding: 0 0 0 30px;
            }
        }

        @media(min-width: $screen-md-min) and (orientation: landscape){

            /* vertically align its content */
            display: table;
            height: 110px;
            #navigation {
                display: table-cell;
                vertical-align: middle;
            }
            ul {
                display: inline-block;
                float: left;
                position: relative;
                width: auto;
                padding-left: 22px;
                padding-top: 10px;
                @include clearfix;

                li {
                    display: inline-block;
                    float: left;
                    padding: 0 20px 0 0;
                    &:last-of-type {
                        padding-right: 0;
                    }

                }

                a {
                    
                    display: inline-block;
                    padding-bottom: 5px;
                }

            }
            .social {
                float: right;
                margin: 0 30px 0 0;
                li {
                    padding: 0 0 0 15px;

                }
            }


        }

    }


}


.content {
    width: 100%;
    height: 100%;
    @include flexbox;
    @include flex-direction($value: column);
    @include align-items($value: stretch);
    @include align-content($value: center);
    @include flex-wrap($value: wrap);
    padding: 0 15px;
    @media(min-width: $screen-md-min) and (orientation: landscape){
        @include flex-direction($value: row);
    }
}


.lateral {
    width: 100%;
    padding: 30px 0px;
    position: relative;
    @include order(2) ;
    @media(min-width: $screen-sm-min) {
        padding: 30px 15px 50px 15px;
    }
    @media(min-width: $screen-md-min) and (orientation: landscape){
        width: 20%;
        @include order(1) ;
        padding-top: 50px;
    }
    @media(min-width: $screen-lg-min) {
        
        width: 25%;
    }



}

.contingut {
    width: 100%;
    padding: 15 15px 15px 15px;
    @include order(1) ;
    @media(min-width: $screen-sm-min) {
        padding:  0 15px 30px 15px;
    }
    @media(min-width: $screen-md-min) and (orientation: landscape){
        width: 80%;
        padding: 30px 15px 30px 15px;
        @include order(2) ;
    }
    @media(min-width: $screen-lg-min) {
        width: 75%;
        padding-top: 50px;

    }
}



// Sidebar menu

.lateral ul, #menu-lateral {
    list-style: none;
    text-transform: uppercase;
    
    li {
        margin: 0;
        padding: 15px 0 30px 15px;
        position: relative;
        font-size: 16px;
        @media(min-width: $screen-md-min) and (orientation: landscape){
            font-size: 16px;
            padding: 15px 0 30px 0;
        }
        @media(min-width: $screen-lg-min) {
            font-size: 20px;
            padding: 15px 0 30px 35px;
        }
        &:before {
            content: '';
            width: 70px;
            position: absolute;
            top: 0;
            left: 0;
            border: 0.5px solid $black;
            display: inline-block;
        }
        a {
            font-weight: 600;
            cursor: default;
            &:hover, &:focus {
                text-decoration: none;

            }

        }
        ul { 
            list-style: none;
            li {
                padding: 5px 0 5px 0;
                line-height: 1.1;
                font-size: 14px;
                @media(min-width: $screen-md-min) and (orientation: landscape){
                    font-size: 13px;
                    padding: 4px 0 4px 0;
                }
                @media(min-width: 1240px) {
                    font-size: 16px;
                    padding: 5px 0 5px 0;
                }
                &:before {
                    border: 0;
                }
                a {
                    font-weight: normal;
                    cursor: pointer;
                    display: block;
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        top: 6px;
                        left: 0;
                        width: 0px;
                        height: 1px;
                        background-color: $theme-primary;
                        @include transition(all linear .2s);
                    }
                    &:hover, &:focus {
                        padding-left: 25px;
                        &:after {
                            
                            width: 15px;
                            height: 1px;
                            background-color: $theme-primary;
                        }
                    }
                }
                &.current-menu-item {
                    a {
                        padding-left: 25px;
                        &:after {
                            
                            width: 15px;
                            height: 1px;
                            background-color: $theme-primary;
                        }
                    }
                }
                ul {
                    li {
                        a {

                        }
                    }
                }
            }
        }
    }
}



// Slide Principal
.slide-principal {
    margin: 15px 0 0 0;
    padding: 0;
    width: 100%;
    float: left;
    @media(min-width: $screen-md-min) and (orientation: landscape){
        margin: -30px 0 0 0;
    }
    @media(min-width: $screen-lg-min) {
        margin: -80px 0 0 0;
    }
    .slide {
        width: 100%;
        float: left;
        display: inline-block;
        height: 0;
        min-height: 0;
        padding-bottom: 66.72398968%;
        background-size: auto 100%;
        background-position: center center;
        //background-color: #000;
        position: relative;
        text-align: center;
        background-repeat: no-repeat;
        //border: 10px solid #fff;
        
   
    }

}




.full-page {
    width: 100%;
    height: 100%;
    padding: 30px 15px;
    @media(min-width: $screen-sm-min) {
        padding: 30px;
    }
    @media(min-width: $screen-md-min) and (orientation: landscape){
        
        
    }
    @media(min-width: $screen-lg-min) {
        padding: 50px 30px 30px 30px;
       
    }

   
}



// destacado con linea
.titulodestacado {
    padding: 18px 0 0 15px;
    position: relative;
    @media(min-width: $screen-sm-min) {
        padding: 18px 0 0 35px;
    }
    @media(min-width: $screen-md-min) and (orientation: landscape){
        padding: 18px 0 0 15px;
    }
    @media(min-width: $screen-lg-min) {
        padding: 18px 0 0 35px;
    }
    &:after {
        width: 70px;
        height: 1px;
        content: '';
        border-top: 1px solid #000;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.textodestacado {
    padding: 15px 0 0 0px;
    position: relative;
    margin: 0 0 15px 0;
    @media(min-width: $screen-sm-min) {
        padding: 15px 0 0 35px;
    }
    @media(min-width: $screen-md-min) and (orientation: landscape){
        padding: 15px 0 0 15px;
    }
    @media(min-width: $screen-lg-min) {
        padding: 15px 0 0 35px;
    }
    &:after {
        width: 70px;
        height: 1px;
        content: '';
        border-top: 1px solid #000;
        position: absolute;
        top: 0;
        left: 0;
    }
}

// titulo
.title {
    text-transform: uppercase;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 50px;
  
    @media(min-width: $screen-lg-min) {
       
        font-size: 40px;
    }
   
}




// Margen top primera foto
.align-top {
    margin-top: 15px;
    @media(min-width: $screen-md-min) and (orientation: landscape){
        margin-top: -30px;
    }
    @media(min-width: $screen-lg-min) {
        margin-top: -80px;
    }
}




// Sessions
.session {
    margin: 0 -15px 30px -15px;
    overflow: hidden;
    .text {
        width: 100%;
        padding: 0 15px 15px 15px;
        float: left;
        display: inline-block;
        @media(min-width: $screen-md-min) {
            width: 50%;
            padding: 0 15px 15px 15px;
        }
        .title {
            font-size: 16px;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        strong, b {
            font-weight: 500;
        }
    }
    .images {
        width: 100%;
        padding:  0 15px 15px 15px;
        float: left;
        display: inline-block;
        @media(min-width: $screen-md-min) {
            width: 50%;
            padding: 0 15px 15px 15px;
        }
    }

    .galeria {
        width: 100%;
        float: left;
        .imatge {
            width: 50%;
            padding: 0;
            float: left;
            display: inline-block;
            .cover {
                width: 100%;
                height: 0;
                min-height: 0;
                padding-bottom: 66.71875%;
                overflow: hidden;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}


// Galeria fotos
.fotos-joana {
    width: 100%;
    float: left;
    .imatge {
        width: 100%;
        padding: 0;
        float: left;
        display: inline-block;
        @media(min-width: $screen-sm-min) {
            width: 25%;
        }
        .cover {
            width: 100%;
            height: 0;
            min-height: 0;
            padding-bottom: 66.71875%;
            overflow: hidden;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}





// Slide fotos
.slide-fotos {
    margin: 0;
    padding: 0;
    width: 100%;
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: none;
    visibility: hidden;
    opacity: 0;
    @include transition(all linear .2s);
    background-color: #fff;
    border-bottom: 5px solid #fff;

}

.galeria-fotos {
    width: 100%;
    float: left;
    position: relative;
    margin-top: 30px;
    @media(min-width: $screen-md-min) and (orientation: landscape){
        margin: -30px 0 0 0;
    }
    @media(min-width: $screen-lg-min) {
        margin: -80px 0 0 0;
    }
    .tancar {
        position: absolute;
        top: -25px;
        right: 0;
        width: 15px;
        height: 25px;
        //font-size: 30px;
        //line-height: 35px;
        background-color: #fff;
        display: none;
        visibility: hidden;
        opacity: 0;
        color: $theme-primary;
        background-image: url('../img/icono-cerrar.svg');
        background-size: 100% auto;
        background-repeat: no-repeat;
        cursor: pointer;
       
    }
}



.galeria-fotos.open  {
    .slide-fotos{
        
        @media(min-width: $screen-sm-min) {
            display: block;
            visibility: visible;
            opacity: 1;
        }
    }
    .tancar {
        @media(min-width: $screen-sm-min) {
            display: block;
            visibility: visible;
            opacity: 1;
        }
    }
}




.imatge-gran {
    width: 100%;
    padding: 0;
    float: left;
    display: inline-block;
    height: 0;
    min-height: 0;
    padding-bottom: 66.71875%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-bottom: 5px solid #fff;
    //display: none;
    &.current {
        display: block;
    }
    .cover {
        width: 100%;
        
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
    }
    img {
        width: auto !important;
        height: 100% !important;
        margin: 0 auto;
    }
}


// mapa
.map-item {
    height: 500px;
    width: 100%;
    float: left;
}


.footer {
    
    font-size: 12px;
    color: #979797;
    width: 100%;
    float: left;
    clear: both;
    @media(min-width: $screen-sm-min) {
        
        
    }
    .rights {
        margin-top: 0;
        margin-left: 30px;
        margin-bottom: 30px;
        @media(min-width: $screen-md-min) and (orientation: landscape){
            margin-top: -80px;
            margin-bottom: 0;
        }
        @media(min-width: $screen-lg-min) {
 
            margin-left: 65px;
        }
    }
}



// 404

.error404 {
    
}

.error404 .navbar-inverse {
    background: transparent !important;
}

.error404 .navbar-nav, .error404 .languages, .error404 .navbar-toggle {
    display: none !important;
}

.error-404 {
    text-align: center;
    width: 100%;
    height: 100vh;
    min-height: 100%;

    @include flexbox;
    @include align-items($value: center);
    background: #e8dcd2 url('../img/404.jpg') center center no-repeat !important;
    background-size: cover !important;


}

.error-404 .message {
    margin: 0 auto 15px auto;
}

.error404 h1 {
    font-weight: normal;
    font-family: $title-font;
    margin: 0;
    color: #fff;
    line-height: 1.3;
    font-size: 16px;
    font-weight: bold;

}

@media (min-width:768px) {
    .error404 h1 {
        font-size: 36px;
    }
}
@media (min-width:1024px) {
    .error404 h1 {
        font-size: 42px;
    }
}

.error404 h1 a {
    color: $theme-primary;
    text-decoration: underline;
}

.error404 .footer {
    display: none;
}




/* Cookie bar */
#cookie-law-info-bar {
    padding: 0 !important;
    border-top: 0 !important;
    color: #000 !important;
    background: transparent !important;
}

#cookie-law-info-bar p {
    margin: 0;
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 1.3;
    font-size: 10px;
    @media (min-width:$screen-sm-min) {
        font-size: 12px;
    }
}

#cookie-law-info-bar p a {
    color: $theme-primary !important;
}

.cookies {
    position: relative;
    padding: 5px 37px;
    border-left: 3px solid $theme-primary;
    border-top: 3px solid $theme-primary;
    border-right: 3px solid $theme-primary;
    background: #f7f7f7;
    @media (min-width:$screen-sm-min) {
        padding: 20px 0;
    }
    @media (min-width:$screen-md-min) {
        padding: 20px 50px;
    }
  
}

.cerrar {
    position: absolute;
 
    right: 10px;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    top: 50%;
    @media (min-width:$screen-sm-min) {
        
        right: 5px;
    }
    @media (min-width:$screen-md-min) {
    
        right: 15px;
    }
    
}

.cerrar a
{
    visibility: hidden;
    font-size: 0;
    display: block;
    &:hover, &:focus {
        text-decoration: none;
    }
}

.cerrar a:before  {
    visibility: visible;
    font-family: 'ElegantIcons';
    font-size: 30px; 
    line-height: 32px;
    display: inline-block; 
    content: "\4d";
    color: #000;
    &:hover, &:focus {
        color: $theme-primary;
    }
    
}


