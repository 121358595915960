@import url('https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

$brand-primary: #515457 !default;
$theme-primary: #515457;
$theme-secondary: #f3f3f3;

$font-family-sans-serif:  'Poppins', sans-serif;
$font-family-serif: 'Poppins', Times, serif !default;

$text-font:  'Poppins', sans-serif;
$title-font: 'Poppins', Georgia,serif;
$button-font: 'Poppins', Helvetica Neue,Helvetica,Arial,sans-serif;


$black: #515457;
$white: #fff;
$bg: #f3f3f3;
$title: #515457;


@mixin padding-left() {
    padding-left: 20px;
    @media(min-width: $screen-sm-min) {
        padding-left: 40px;
    }
    @media(min-width: $screen-md-min) {
        padding-left: 80px;
    }
}


//$brand-primary: darken(#428bca, 6.5%) !default; // #337ab7