
// Global Components
html, body {
    height: 100%;
    width: 100%;

}
body {
    font-size: 12px;
    font-weight: normal;
    overflow-x: hidden;
    color: #4D4D4D;
    @media (min-width:$screen-sm-min) {
        font-size: 12px;
    }
    @media (min-width:$screen-lg-min) {
        font-size: 14px;
    }
}

.ingles {
    color: #999999;
}


h1, h2, h3, h4, h5 {
    
    line-height: 1.2;
    font-weight: normal;
    
    margin-top: 30px;
    margin-bottom: 15px;
    font-family: $title-font;
    span, em {
        color: $theme-primary;
   
    }
}


h1 {
    font-size: 30px;
    @media(min-width:$screen-sm-min) {
        font-size: 40px;
    }
    @media(min-width:$screen-md-min) {
        font-size: 50px;
    }
}

h2 {
    font-size: 24px;
    @media(min-width:$screen-sm-min) {
        font-size: 28px;
    }
    @media(min-width:$screen-md-min) {
        font-size: 32px;
    }
    
}

h3 {
    font-size: 20px;

    @media(min-width:$screen-sm-min) {
        font-size: 24px;
    }
    @media(min-width:$screen-md-min) {
        font-size: 28px;
    }
    
}

h4 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: $font-family-sans-serif;
   
    
}

h5 {
    font-size: 16px;
    font-family: $font-family-sans-serif;
    font-weight: normal;
    
}

p, ul, ol {
    line-height: 1.6;
    margin-bottom: 15px;
}


ul, ol {
    li {
        margin-left: 25px;
    }
}

.vc_column-inner  ul li{
    list-style: none;
    margin: 0 0 5px 0;
    padding-left: 10px;
    position: relative;

}

.vc_column-inner  ul li:before {
   
    content:"·";
    position: absolute;
    left: 0;
    vertical-align:middle;
   

}

b, strong {
    font-weight: 500;
}

a {
    color: $brand-primary;
    @include transition(all linear .2s);
     text-decoration: none;
}


:focus {
    outline: 0 !important;
}
:root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}


div, article, aside, details, figcaption, figure,
footer, header, main, menu, nav, section, summary, details, form {
    display: block;
}

section {
    width: 100%;
    float: left;
}

/* Disable input[type=number] buttons until the world is ready */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    display: none;
}

input[type="number"] {
    width: auto;
    -moz-appearance: textfield;
}

input[type="search"] {
    -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
main {
    display: block;
}
summary {
    display: block;
}
pre {
    overflow: auto;
}
progress {
    display: inline-block;
}
small {
    font-size: 75%;
}
template {
    display: none;
}
textarea {
    overflow: auto;
}
*, ::before, ::after {
    box-sizing: border-box;
}
* {
    font-size: inherit;
    line-height: inherit;
}
::before, ::after {
    text-decoration: inherit;
    vertical-align: inherit;
}
*, ::before, ::after {
    border-style: solid;
    border-width: 0;
}
* {
    margin: 0;
    padding: 0;
}
html {
    background-color: $white;
}

audio, canvas, iframe, img, svg, video {
    vertical-align: middle;
}
button, input, select, textarea {
    background-color: $bg;
}
button, input, select, textarea {
    color: inherit;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
}




button, [type="button"], [type="date"], [type="datetime"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="reset"], [type="search"], [type="submit"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], select, textarea {
    min-height: 1.5em;
}
code, kbd, pre, samp {
    font-family: monospace, monospace;
}
nav ol, nav ul {
    list-style: none;
}
select {
    -moz-appearance: none;
    -webkit-appearance: none;
}
select::-ms-expand {
    display: none;
}
select::-ms-value {
    color: currentColor;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
textarea {
    resize: vertical;
}
::-moz-selection {
    background-color: #B3D4FC;
    text-shadow: none;
}
::selection {
    background-color: #B3D4FC;
    text-shadow: none;
}

.container {
    max-width: 400px;
    @media (min-width:$screen-sm-min) {
        max-width: 750px;
    }
    @media (min-width:$screen-md-min) {
        max-width: 970px;
    }
    @media (min-width:$screen-lg-min) {
        max-width: 1170px;
    }
}


.contingut.ls-overflow-visible {
    overflow: hidden !important;
}


.space {
    margin: 30px 0 30px 0;
    height: 25px;
    width: 100%;
   
    
}

.margins {
    display: block;
    margin: 15px auto;
}

// Owl Carousel
.owl-carousel .owl-nav {
    margin-top: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent; 
  
}
.owl-carousel .owl-nav [class*='owl-'] {
    width: 50%;
    height: 100%;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    position: absolute;
    top: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #D6D6D6;
    @include border-radius(50%);
    display: none;
    @media(min-width:$screen-sm-min) {
      width: 50%;
      font-size: 30px;
      display: inline-block;
    
    }
    
}
.owl-carousel .owl-nav .owl-prev {
    left: -30px;
    cursor: url(../img/prev.png),default;
    &:hover {
        
    }
    i {
       display: none;
    }
}

.owl-carousel .owl-nav .owl-next {
    right: -30px;
     cursor: url(../img/next.png),default;
    &:hover {
    }
    i {
      display: none;
    }
}


.owl-carousel .owl-nav [class*='owl-']:hover {
    background: transparent;
    color: #fff;
    text-decoration: none; 
}
.owl-carousel .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; 
}

.owl-carousel .owl-nav.disabled + .owl-dots {
    margin-top: 0; 
}

.owl-carousel .owl-dots {
  
    margin-bottom: 0;
    text-align: center;
    -webkit-tap-highlight-color: transparent; 
    position: absolute;
    bottom: -30px;
    left: auto;
    right: 0;
    counter-reset: step;
    @media(min-width:$screen-lg-min) {
        left: -30px;
        right: auto;
        bottom: -5px;
    }
    
    
}
.owl-carousel .owl-dots .owl-dot {
    display: inline-block;
    float: left;
    zoom: 1;
    //*display: inline; 
    position: relative;
    padding: 0 0 0 10px;
    @media(min-width:$screen-lg-min) {
        display: block;
        float: none;
        padding: 0 0 0 5px;
    }
}

.owl-carousel .owl-dots .owl-dot  {
    
}


.owl-carousel .owl-dots .owl-dot span:before  {
    content: "0" counter(step) "";
    counter-increment: step;
    font-size: 11px;
    font-weight: lighter;
    @media(min-width:$screen-lg-min) {
        font-size: 12px;
        line-height: 2;
    }
    
}
.owl-carousel .owl-dots .owl-dot.active span:before, .owl-carousel .owl-dots .owl-dot:hover span:before, .owl-carousel .owl-dots .owl-dot:focus span:before  {
    color: #000;
    font-weight: normal;

}

.owl-carousel .disabled {
    display: none;
}




// Button Extend  
// Button Extend  
.buttons {
    display: inline-block;
    padding: 6px 20px 6px 20px !important;
    text-align: center;
    line-height: 1.3 !important;
    text-decoration: none !important;
    white-space: normal !important;
    background: $theme-primary !important;
    //@include border-radius(5px);
    border-radius: 5px !important;
    border: 1px solid $theme-primary !important;
    cursor: pointer;
    //font-weight: 200 !important;
    margin: 5px 0;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, .1) !important;
    font-family: $button-font !important;
    color: #fff !important;
    min-width: 160px;

    @media (min-width:$screen-sm-min) {
        padding: 10px 20px 10px 20px !important;
    }

}

.buttons-hover {
    border-bottom: 0 !important;
    color: $theme-primary !important;
    background: #fff !important;
    border: 1px solid $theme-primary !important;
    text-decoration: none !important;
}

// Button Output
a.button,
.button, .posts-links a, .btn, .button {
  @extend .buttons;
  &:hover,
  &:focus,
  &:active {
    @extend .buttons-hover;
  }
}

input {
  &[type="submit"],
  &[type="reset"],
  //&[type="button"] 
  {
    @extend .buttons;
    &:hover,
    &:focus,
    &:active {
      @extend .buttons-hover;
    }
  }
}


// Go top
a.go-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 30px;
    z-index: 999;

    &:hover, &:focus {
        text-decoration: none;
        opacity: 0.8;
    }
}
